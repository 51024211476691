import { useEffect, useMemo, useState } from 'react';
import { CreditCardModal } from '@/components/features/CreditCardModal';
import { useRouter } from 'next/router';
import { makeLogger } from '@/utils/makeLogger';
import {
  CustomerDashboardQuery,
  JobListDetailsFragment,
  JobStatus,
} from 'src/__generated__/graphql';
import Button from '@/components/ui/Button';
import Icon from '@/components/ui/icon';
import Modal from '@/components/ui/Modal';
import { AppUserQuery } from '@/utils/narrow-utils';

const log = makeLogger('pages/index');

interface ModalsProps {
  customerData?: AppUserQuery<CustomerDashboardQuery, 'Customer'> | null;
  customerDataLoading: boolean;
  jobList: JobListDetailsFragment[];
  prepaidCanBookWithoutCC?: boolean | null;
}

const Modals = ({
  customerData,
  customerDataLoading,
  jobList,
  prepaidCanBookWithoutCC,
}: ModalsProps) => {
  const router = useRouter();

  const showAddCardModal = useMemo(() => {
    // by waiting until this is loaded, whenever we render the
    // modal, we can verify which case the user is in to change the modal,
    // 1. apple pay - booking is confirmed,
    // 2. paypal - booking is NOT confirmed,

    // loading -> don't show modal
    // has a card NOT apple pay and not prepaid (where can not book without cc)  -> don't show modal
    const hasCardNotApplePay =
      customerData?.me?.savedPaymentMethod?.last4 &&
      customerData?.me?.savedPaymentMethod?.walletType !== 'apple_pay';
    if (
      customerDataLoading ||
      (hasCardNotApplePay && !prepaidCanBookWithoutCC)
    ) {
      log('[showAddCardModal]', false, {
        customerDataLoading,
        savedPayment: customerData?.me?.savedPaymentMethod,
      });
      return false;
    }

    // if user can book without a cc, then immediately show the add card modal once if
    // ?add_card=true is in the url
    if (router?.query?.add_card === 'true') {
      log('[showAddCardModal]', true, 'query string');
      return true;
    }

    // user landing on this page from another page, no query param
    // has card -- returns earlier, don't show.
    // no card --
    //   claimed job -- show modal
    if (
      jobList.find((j) => {
        return j.status === JobStatus.Claimed;
      })
    ) {
      log('[showAddCardModal]', true, 'claimed job');
      return true;
    }

    log('[showAddCardModal]', false, 'default');
    return false;
  }, [
    customerData?.me?.savedPaymentMethod,
    jobList,
    router?.query?.add_card,
    customerDataLoading,
    prepaidCanBookWithoutCC,
  ]);

  // Booking confirmed modal
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [fcRestarted, setFCRestarted] = useState(false);
  const [apptHasPriorityMarkup, setApptHasPriorityMarkup] = useState(false);
  useEffect(() => {
    if (router?.query?.booked === 'true') {
      log('[showBookingConfirmedModal]', true);
      if (router.query.fcr === 'true') {
        setFCRestarted(true);
      }
      if (router.query.pm === 'true') {
        setApptHasPriorityMarkup(true);
      }
      setShowBookingModal(true);
      router.push('/', undefined, { shallow: true });
    }
  }, [router]);

  return (
    <>
      {showBookingModal && (
        <Modal
          title={
            apptHasPriorityMarkup
              ? 'Your priority request has been received'
              : `Thanks for booking!`
          }
          isOpen={showBookingModal}
          close={() => setShowBookingModal(false)}
        >
          <div className="max-w-[400px]">
            <p>
              We&apos;ll send you the exact time and date once you&apos;ve been
              matched with a cleaner.
            </p>
            {/* FC was restarted on booking */}
            {fcRestarted && (
              <div className="p-4 flex flex-row items-center justify-center bg-lightestPurple rounded-lg mt-4">
                <Icon
                  name="seal-check"
                  size={32}
                  className="fill-purple mr-3"
                />
                <div>
                  <p className="font-bold">Welcome back!</p>
                  <p>
                    You can now enjoy all the benefits of our membership plan.
                  </p>
                </div>
              </div>
            )}
            <Button
              className="mt-6 w-full"
              onClick={() => setShowBookingModal(false)}
            >
              Okay, got it!
            </Button>
          </div>
        </Modal>
      )}
      {showAddCardModal && (
        // apple pay users with saved cc will see this modal, but we will say their booking
        // IS confirmed, but paypal/prepaid users will say NOT confirmed, since they have NO cc.
        <CreditCardModal
          bookingConfirmed={
            !!customerData?.me?.savedPaymentMethod?.last4 &&
            !prepaidCanBookWithoutCC
          }
        />
      )}
    </>
  );
};

export default Modals;
