import clsx from 'clsx';
import { PropsWithChildren } from 'react';

export default function Badge({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <div
      className={clsx(
        'inline-flex items-center rounded-md px-1.5 py-0.5 text-xs font-bold',
        className,
        !className?.includes('bg-') && 'bg-gray',
      )}
    >
      {children}
    </div>
  );
}
