import { ErrorBoundary } from '@sentry/nextjs';
import ErrorFallback from '@/components/ui/ErrorFallback';
import Dashboard from '@/components/features/Dashboard';

const Home = () => {
  return (
    <ErrorBoundary fallback={<ErrorFallback />}>
      <Dashboard />
    </ErrorBoundary>
  );
};
export default Home;
