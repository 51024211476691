import { isNil } from 'lodash-es';

// Set up a utility function to assert there are no authentication
// errors and properly narrow the type to that of a specific user
type GraphQLOperation = {
  __typename?: string | null;
  me?: { __typename?: string | null } | null;
};

/**
 * A utility type to extract what is inside the UserBoundary `me?` attribute of
 * the GraphQL operation type, depending on the passed user type.
 */
type ForAppUser<T extends GraphQLOperation, U extends string> = Extract<
  T['me'],
  { __typename?: U }
>;

export type AppUserQuery<T extends GraphQLOperation, U extends string> = {
  me?: ForAppUser<T, U>;
};

function isSpecificUserOperation<T extends GraphQLOperation, U extends string>(
  obj: T | undefined | null,
  desiredUser: U,
): boolean {
  return obj?.me?.__typename === desiredUser;
}

/**
 * A utility function that should be used to create a client-specific
 * function for narrowing the GraphQL response type to that of the
 * user corresponding to the client.
 *
 * @example
 * export function throwIfUnauthenticatedCleaner<T extends GraphQLOperation>(
 *   gqlType: T | undefined | null,
 * ) {
 *   return throwIfUnauthenticated(gqlType, "Cleaner");
 * }
 *
 * And then that function gets used as follows:
 * @example
 * const { data: _data, loading, error } = useMyQuery()
 * const data = throwIfUnauthenticatedCleaner(_data)
 * // data is now fully typed based on the query
 *
 * @param gqlType A graphQL query data object
 * @param desiredUser One of the possible types from the UserBoundary union
 *   in the GraphQL schema
 */
function narrowTypeCheckIfUnauthenticated<
  T extends GraphQLOperation,
  U extends string,
>(
  gqlType: T | undefined | null,
  desiredUser: U,
): AppUserQuery<T, U> | undefined | null {
  if (isSpecificUserOperation(gqlType, desiredUser) || isNil(gqlType))
    return gqlType as AppUserQuery<T, U> | undefined | null;
  // Must have gotten an authentication error. In the future, once clients
  // have better error handling, we should throw an error for unauthenticated.
  // For now, just return undefined, and expose a hook that allows the client
  // to handle the error.
  // Throw an error that is immediately caught, so that we can still
  // recover the stack trace in client logging
  throw new Error('Unauthenticated response from GraphQL server');
}

export function throwIfUnauthenticatedCustomer<T extends GraphQLOperation>(
  gqlType: T | undefined | null,
) {
  return narrowTypeCheckIfUnauthenticated(gqlType, 'Customer');
}
