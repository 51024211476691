import Modal from '@/components/ui/Modal';
import { useCallback, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import * as Sentry from '@sentry/nextjs';
import { CreditCardFormData, creditCardshape } from '@/utils/forms';
import Button from '@/components/ui/Button';
import toast from '@/utils/toast';
import useUpdateSavedPayment from 'src/data/hooks/useUpdateSavedPayment';
import CreditCardFields from './CreditCardFields';

export const CreditCardModal = ({
  bookingConfirmed,
}: {
  bookingConfirmed: boolean;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const schema = yup.object().shape(creditCardshape);
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm<CreditCardFormData>({
    defaultValues: {
      creditCardNumber: '',
      ccExpires: '',
      cvc: '',
    },
    resolver: yupResolver(schema),
  });

  const [updateSavedPayment] = useUpdateSavedPayment({
    onError: (err) => {
      Sentry.captureException(err);
      toast.error(
        'Check your credit card info and try again. If this error persists, please contact support.',
      );
    },
    onCompleted(data) {
      if (data?.updateCustomerSavedPayment?.success) {
        toast.success('Your card has been saved.');
        closeModal();
        return;
      } else {
        toast.error(
          'Error saving your payment info. Please check your payment info and try again.',
        );
      }
    },
  });

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const submit = useCallback(
    async (data: CreditCardFormData) => {
      await updateSavedPayment({
        variables: {
          input: {
            expYear: data.ccExpires?.split('/')[1] || '',
            expMonth: data.ccExpires?.split('/')[0] || '',
            cvc: data.cvc,
            number: data.creditCardNumber,
          },
        },
      });
    },
    [updateSavedPayment],
  );

  // don't show if loading, don't show if saved payment present
  if (closed) {
    return null;
  }

  return (
    <Modal
      title={`Your appointment is ${bookingConfirmed ? '' : 'not'} confirmed`}
      isOpen={isOpen}
      close={closeModal}
    >
      <div className="flex flex-col">
        <p>
          We require a credit card in case extra hours are requested. You
          won&apos;t be charged until your cleaning is completed.
        </p>
        <form onSubmit={handleSubmit(submit)} className="flex flex-col">
          <CreditCardFields
            control={control}
            errors={errors}
            watch={watch}
            className="mt-4"
          />
          <Button
            className="mt-6 flex-1 p-4"
            loading={isSubmitting}
            type="submit"
          >
            Add Credit Card
          </Button>
        </form>
      </div>
    </Modal>
  );
};
