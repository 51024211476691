import { PROFILE_BANNER_DISMISSED } from '@/utils/constants';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import useDismissibleBanner from './useDismissibleBanner';
import Banner from '@/components/ui/Banner';

const UpdateProfileBanner = () => {
  const { isDismissed, dismissBanner } = useDismissibleBanner(
    PROFILE_BANNER_DISMISSED,
  );

  if (isDismissed) {
    return null;
  }

  return (
    <Banner
      href="/settings/profile"
      variant="success"
      hasProgressBar
      onDismiss={dismissBanner}
      title={
        <span className="flex items-center">
          You&apos;re almost done!
          <BsBoxArrowUpRight
            className="inline ml-2"
            aria-label="links to profile page"
          />
        </span>
      }
      text="Take a couple minutes to update your profile. This helps us match you with the best cleaner!"
    />
  );
};

export default UpdateProfileBanner;
