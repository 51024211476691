import {
  InputHTMLAttributes,
  useMemo,
  forwardRef,
  useId,
  RefCallback,
} from 'react';
import styles from './index.module.css';
import Icon, { IconName } from '../icon';
import colors from '@/utils/colors';
import clsx from 'clsx';
import { IMaskInput } from 'react-imask';

type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  mask?: string;
  className?: string;
  errorMessage?: string;
  successMessage?: string;
  disabled?: boolean;
  readOnly?: boolean;
  leftIcon?: IconName;
  onChange: (_event: { target: { name: string; value: string } }) => void;
  name?: string;
};

// uses forwardRef to allow the parent component to access the input ref
// for libraries like react-hook-form to work
const TextInput = forwardRef(function TextInput(
  {
    label,
    mask,
    className = '',
    errorMessage,
    leftIcon,
    ...inputProps
  }: TextInputProps,
  ref,
) {
  // if there is a passed in id, use that. Otherwise, generate one from the label
  const randomId = useId();
  const id = useMemo(
    () => inputProps.id || randomId,
    [inputProps.id, randomId],
  );

  const inputCompProps = useMemo(
    () => ({
      ...inputProps,
      // we need to have a placeholder to make the label float,
      // but we don't want to show it.
      // TODO -- figure out a way to show a placeholder later
      placeholder: ' ',
      id: id,
      className:
        styles.input +
        ' bg-lightestGray ' +
        (inputProps.disabled ? ' cursor-not-allowed' : '') +
        (errorMessage ? ' !border-2 !border-darkerRed' : '') +
        (leftIcon ? ' !pl-14' : ''),
      disabled: inputProps.disabled,
      'aria-errormessage': errorMessage ? `${id}-error` : undefined,
      'aria-invalid': !!errorMessage,
    }),
    [errorMessage, id, inputProps, leftIcon],
  );

  return (
    <div className={styles.container + ' ' + className}>
      {leftIcon && (
        <div className="absolute left-4 top-[17px]">
          <Icon name={leftIcon} size={24} color={colors.brand.gray.dark} />
        </div>
      )}
      {mask ? (
        // IMaskInput implementation based on this comment for
        // compatibility with react-hook-form
        // https://github.com/orgs/react-hook-form/discussions/8464#discussioncomment-2893895
        // @ts-ignore -- TODO I'm not sure why the types are failing for this input
        <IMaskInput
          {...inputCompProps}
          mask={mask}
          inputRef={ref as RefCallback<any>}
          onAccept={(value: any) => {
            inputProps?.onChange?.({
              target: { name: inputCompProps.name || '', value },
            });
          }}
        />
      ) : (
        <input
          {...inputCompProps}
          // @ts-ignore
          ref={ref}
        />
      )}

      <label
        htmlFor={id}
        className={clsx(
          styles.label,
          errorMessage ? ' text-darkerRed' : ' text-darkGray',
          leftIcon && '!pl-12',
        )}
      >
        {label}
      </label>
      {errorMessage ? (
        <p
          className="mt-1 text-darkerRed text-sm font-medium"
          id={`${id}-error`}
        >
          {errorMessage}
        </p>
      ) : null}
    </div>
  );
});

export default TextInput;

/**
 *  Below are pre-masked versions of the TextInput
 */
export const PhoneInput = (
  props: Omit<
    TextInputProps,
    'mask' | 'inputMode' | 'type' | 'autoComplete' | 'label'
  >,
) => {
  return (
    <TextInput
      mask="(000) 000-0000"
      inputMode="tel"
      type="tel"
      autoComplete="tel-national"
      label="Phone"
      {...props}
    />
  );
};

export const USStateInput = (
  props: Omit<TextInputProps, 'mask' | 'autoComplete' | 'label'>,
) => {
  return (
    <TextInput
      autoComplete="address-level1"
      mask={'aa'}
      label="State"
      {...props}
    />
  );
};

export const ZipcodeInput = (
  props: Omit<TextInputProps, 'mask' | 'autoComplete' | 'label'>,
) => {
  return (
    <TextInput
      autoComplete="postal-code"
      label="Zipcode"
      mask="00000"
      {...props}
    />
  );
};
