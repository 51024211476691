/**
 * Use this file to share common form schemas, like CC, address, etc and their
 * associated types.
 *
 * This will help us keep forms across the app uniform and allows a single place
 * to fix bugs
 */

import * as yup from 'yup';

export type CreditCardFormData = {
  creditCardNumber: string | null;
  ccExpires: string | null;
  cvc: string | null;
};

export const creditCardshape = {
  creditCardNumber: yup
    .string()
    .min(15, 'Credit card number is invalid') // amex is 15 digits
    .max(60, 'Credit card number is invalid')
    .test('test-cc', 'Credit card number is invalid', (value) => {
      const stripped = value ? (value as string).replaceAll(/[ _]/g, '') : '';
      // numbers only and between 15 and 16 digits
      return /^([0-9]){15,16}$/.test(stripped);
    })
    .required('Credit card number is required'),
  ccExpires: yup.string().required('Credit card expiration is required'),
  cvc: yup.string().required('CVC is required'),
};

export interface AddressFormData {
  street: string | null;
  apt: string | null;
  city: string | null;
  state: string | null;
  zipcode: string | number | null;
}

const states = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const addressShape = {
  street: yup.string().required('Street address is required'),
  apt: yup.string(),
  city: yup.string().required('City is required'),
  state: yup
    .string()
    .matches(/^([a-zA-Z]){2}$/, 'State should be valid')
    .test('test-state', 'State should be valid', (value) => {
      const candidate = value?.toUpperCase();
      return states.includes(candidate as string);
    })
    .required('State is required'),
  zipcode: yup
    .string()
    .matches(/^([0-9])*$/, 'Zipcode should be valid')
    .length(5, 'Please enter a 5-digit zipcode')
    .required('Zipcode is required'),
};

export interface NameFormData {
  firstName: string;
  lastName: string;
}
export const nameShape = {
  firstName: yup.string().trim().required('First name is required'),
  lastName: yup.string().trim().required('Last name is required'),
};

export interface PhoneFormData {
  phone: string;
}

export const stripPhoneMask = (phone?: string) =>
  // todo -- check this without the escape
  // eslint-disable-next-line no-useless-escape
  phone?.replaceAll(/[\(\)\s\-_]/g, '') || '';

export const phoneShape = {
  phone: yup
    .string()
    // strip out the mask characters and check length
    .test('test-phone', 'Please enter a valid phone number', (value) => {
      const stripped = stripPhoneMask(value);
      // numbers only and 10 digits
      return /^([0-9]){10}$/.test(stripped);
    })
    .required('Please enter a valid phone number'),
};
