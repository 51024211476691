import { MutationHookOptions, useMutation } from '@apollo/client';
import { gql } from 'src/__generated__';
import {
  UpdateSavedPaymentMutationMutation,
  UpdateSavedPaymentMutationMutationVariables,
} from 'src/__generated__/graphql';

const UPDATE_SAVED_PAYMENT = gql(/* GraphQL */ `
  mutation UpdateSavedPaymentMutation(
    $input: UpdateCustomerSavedPaymentInput!
  ) {
    updateCustomerSavedPayment(input: $input) {
      message
      success
      customer {
        id
        savedPaymentMethod {
          id
          walletType
          last4
          expMonth
          expYear
          brand
          funding
        }
      }
    }
  }
`);

const useUpdateSavedPayment = (
  opts?: MutationHookOptions<
    UpdateSavedPaymentMutationMutation,
    UpdateSavedPaymentMutationMutationVariables
  >,
) => {
  const mutationResponse = useMutation(UPDATE_SAVED_PAYMENT, opts);
  return mutationResponse;
};

export default useUpdateSavedPayment;
