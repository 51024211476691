import { gql } from 'src/__generated__';
import {
  JobListDetailsFragment,
  JobsListQuery,
} from 'src/__generated__/graphql';

export const JOBS_LIST = gql(/* GraphQL */ `
  query JobsList($filter: BasicJobFilter, $after: String, $first: Int) {
    me {
      ... on Customer {
        id
        jobs(filter: $filter, after: $after, first: $first) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              ...JobListDetails
            }
          }
        }
      }
    }
  }

  fragment JobListDetails on Job {
    id
    rawId
    cleaner {
      id
      rawId
      avgStars
      firstName
      lastName
      photo
      numReviews
    }
    reviews {
      id
      stars
    }
    advancedReview {
      id
      stars
    }
    recurringContract {
      id
      frequency
    }
    location {
      id
      street
      apt
      lat
      lng
      cleaningNotes
    }
    extraServices
    numHours
    start
    preferredStartWindows {
      startOfWindow
      endOfWindow
      priorityMarkupPct
    }
    customerTip {
      amount
    }
    invoicedDate
    status
    customerRequestedEta
    legacyAlerts {
      ... on CPRescheduledNotRequestedAlert {
        __typename
        date
      }
      ... on CPSwappedAlert {
        cpFirstName
      }
      ... on HoursChangedCPNotApprovedAlert {
        lastApprovedNumHours
      }
      ... on ChargedForLastMinuteCancelAlert {
        date
      }
      ... on ChargedForLockoutAlert {
        date
      }
    }
  }
`);

// narrows a data response from jobs list to the actual jobs list nodes
export const jobListLens = (data?: JobsListQuery) => {
  if (data?.me?.__typename !== 'Customer') return [];
  return (
    (data?.me?.jobs?.edges
      ?.map((edge) => edge?.node)
      ?.filter(Boolean) as Array<JobListDetailsFragment>) || []
  );
};
