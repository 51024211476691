import { useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { jobListLens, JOBS_LIST } from './utils';
import {
  JobsListQuery,
  JobsListQueryVariables,
  JobSortDirection,
} from 'src/__generated__/graphql';

const cancelledJobsVariables: JobsListQueryVariables = {
  filter: {
    jobStatus: ['cancelled'],
    sortDir: JobSortDirection.Desc,
  },
  first: 2,
};

const useGetCancelledJobs = () => {
  const {
    data: cancelledJobsData,
    fetchMore: _fetchMoreCancelledJobs,
    loading: cancelledJobsLoading,
  } = useQuery<JobsListQuery, JobsListQueryVariables>(JOBS_LIST, {
    variables: cancelledJobsVariables,
  });
  const cancelledJobList = useMemo(
    () => jobListLens(cancelledJobsData),
    [cancelledJobsData],
  );
  const fetchMoreCancelledJobs = useCallback(() => {
    _fetchMoreCancelledJobs({
      variables: {
        after:
          cancelledJobsData?.me?.__typename === 'Customer'
            ? cancelledJobsData.me.jobs?.pageInfo?.endCursor
            : null,
      },
    });
  }, [_fetchMoreCancelledJobs, cancelledJobsData]);

  return {
    cancelledJobsData,
    cancelledJobList,
    cancelledJobsLoading,
    fetchMoreCancelledJobs,
  };
};

export default useGetCancelledJobs;
