import colors from '@/utils/colors';
import { ReactNode } from 'react';
import Icon, { IconName } from './icon';
import { BsX } from 'react-icons/bs';
import clsx from 'clsx';
import Link from 'next/link';

const ButtonOrLink = ({
  href,
  onClick,
  className,
  children,
}: {
  href?: string;
  onClick?: () => void;
  className?: string;
  children: ReactNode;
}) => {
  if (href) {
    return (
      <Link href={href} className={className}>
        {children}
      </Link>
    );
  }

  return (
    <button className={className} onClick={onClick}>
      {children}
    </button>
  );
};

interface BannerProps {
  title?: ReactNode;
  text?: ReactNode;
  iconName?: IconName;
  href?: string;
  variant?: 'success' | 'warning' | 'error';
  hasProgressBar?: boolean;
  onClick?: () => void;
  onDismiss?: (_opts: any) => void;
}

const Banner = ({
  href,
  title,
  text,
  iconName,
  variant,
  hasProgressBar,
  onClick,
  onDismiss,
}: BannerProps) => {
  return (
    <div className="w-full sm:px-1 md:px-2 lg:px-0 relative">
      <ButtonOrLink
        className={clsx(
          'flex flex-col w-full text-left mb-2 rounded-lg overflow-hidden',
          variant === 'success' && 'bg-green',
          variant === 'warning' && 'bg-orange',
          variant === 'error' && 'bg-lightestRed',
          !variant && 'bg-lightGray',
        )}
        onClick={onClick}
        href={href}
      >
        <div className="flex flex-row items-center my-4 px-4 pr-14">
          {!!iconName && (
            <div className="w-5 h-5 flex-shrink-0">
              <Icon name={iconName} size={24} color={colors.brand.black} />
            </div>
          )}
          <div className={clsx('text-md', !!iconName && 'ml-4')}>
            {!!title && <p className="font-bold">{title}</p>}
            {!!text && <p className="font-normal">{text}</p>}
          </div>
        </div>
        {hasProgressBar && (
          <div className="w-full h-1">
            <div
              className={clsx(
                'w-2/3 h-full rounded-r',
                variant === 'success' && 'bg-darkGreen',
                variant === 'warning' && 'bg-red',
                variant === 'error' && 'bg-darkerRed',
                !variant && 'bg-darkGray',
              )}
            ></div>
          </div>
        )}
      </ButtonOrLink>
      {!!onDismiss && (
        <div className="absolute top-0 right-0 h-full flex items-center mr-4 pointer-events-none">
          <button
            onClick={onDismiss}
            className="h-11 w-11 flex justify-center items-center pointer-events-auto"
            aria-label="Close home services banner"
          >
            <BsX className="h-5 w-5" />
          </button>
        </div>
      )}
    </div>
  );
};

export default Banner;
