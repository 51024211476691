import { useCallback, useState } from 'react';

const useDismissibleBanner = (
  bannerId: string,
): { isDismissed: boolean; dismissBanner: (_opts: any) => void } => {
  const [isDismissed, setIsDismissed] = useState(
    typeof localStorage === 'undefined'
      ? true
      : localStorage.getItem(bannerId) === 'true',
  );

  const dismissBanner = useCallback(
    (e: any) => {
      e?.preventDefault?.();
      if (typeof localStorage === 'undefined') return;
      localStorage.setItem(bannerId, 'true');
      setIsDismissed(true);
    },
    [bannerId],
  );

  return { isDismissed, dismissBanner };
};

export default useDismissibleBanner;
