import { FC_CANCELLED_BANNER_DISMISSED } from '@/utils/constants';
import { ForeverCleanStatus } from 'src/__generated__/graphql';
import { formatDate } from '@/utils/helper';
import { benefitsEndsDate } from '@/utils/membershipUtils';
import useDismissibleBanner from './useDismissibleBanner';
import Banner from '@/components/ui/Banner';
type MembershipCancelledBannerProps = {
  membershipStatus: ForeverCleanStatus;
  paidThrough: string;
};

export function MembershipCancelledBanner({
  membershipStatus,
  paidThrough,
}: MembershipCancelledBannerProps) {
  const { isDismissed, dismissBanner } = useDismissibleBanner(
    FC_CANCELLED_BANNER_DISMISSED,
  );

  const wasCancelled =
    membershipStatus === ForeverCleanStatus.Cancelled ||
    membershipStatus === ForeverCleanStatus.PendingCancellation;

  if (isDismissed || !wasCancelled) {
    return null;
  }

  const hasMembershipEnded = new Date(paidThrough) < new Date();
  const paidThroughFormatted = formatDate(benefitsEndsDate(paidThrough));

  return (
    <Banner
      href="/settings/membership"
      title={
        <>
          {hasMembershipEnded && 'Your membership has been cancelled'}
          {!hasMembershipEnded && (
            <>
              You&apos;ll lose your access to your membership on{' '}
              {paidThroughFormatted}
            </>
          )}
        </>
      }
      text={
        hasMembershipEnded
          ? 'Reactivate to use your benefits'
          : 'Reactivate to keep your benefits'
      }
      iconName="lightning"
      variant="error"
      onDismiss={dismissBanner}
    />
  );
}
