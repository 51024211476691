import { useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { jobListLens, JOBS_LIST } from './utils';
import {
  JobsListQuery,
  JobsListQueryVariables,
  JobSortDirection,
} from 'src/__generated__/graphql';
import { startOfDay } from '@/utils/helper';

const pastJobsVariables: JobsListQueryVariables = {
  filter: {
    jobStatus: ['claimed', 'submitted', 'pending_invoice', 'invoiced'],
    startLte: startOfDay()?.toISOString() || new Date().toISOString(),
    sortDir: JobSortDirection.Desc,
  },
  first: 2,
};

const useGetPastJobs = () => {
  const {
    data: pastJobsData,
    fetchMore: _fetchMorePastJobs,
    loading: pastJobsLoading,
  } = useQuery<JobsListQuery, JobsListQueryVariables>(JOBS_LIST, {
    variables: pastJobsVariables,
  });
  const pastJobList = useMemo(() => jobListLens(pastJobsData), [pastJobsData]);
  const fetchMorePastJobs = useCallback(() => {
    _fetchMorePastJobs({
      variables: {
        after:
          pastJobsData?.me?.__typename === 'Customer'
            ? pastJobsData.me.jobs?.pageInfo?.endCursor
            : null,
      },
    });
  }, [_fetchMorePastJobs, pastJobsData]);

  return { pastJobsData, pastJobList, pastJobsLoading, fetchMorePastJobs };
};

export default useGetPastJobs;
