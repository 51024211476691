import { REWARDS_BANNER_DISMISSED } from '@/utils/constants';
import { useEffect } from 'react';
import Mixpanel from '@/utils/mixpanel';
import useDismissibleBanner from './useDismissibleBanner';
import Banner from '@/components/ui/Banner';
type RewardsBannerProps = {
  loading: boolean;
  cashOutDiscountRate: number;
};

export function RewardsBanner({
  loading,
  cashOutDiscountRate,
}: RewardsBannerProps) {
  const { isDismissed, dismissBanner } = useDismissibleBanner(
    REWARDS_BANNER_DISMISSED,
  );

  useEffect(() => {
    if (!isDismissed) {
      Mixpanel.track('rewards_banner_shown');
    }
  }, [isDismissed]);

  if (isDismissed || loading) return null;
  return (
    <Banner
      href="/rewards"
      title={<span>Earn {(cashOutDiscountRate ?? 0.2) * 100}% cash back</span>}
      text="Get cash back on eligible home-related services"
      iconName="dollar-circle-fill"
      variant="success"
      onDismiss={dismissBanner}
    />
  );
}
