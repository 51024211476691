import clsx from 'clsx';
import TextInput from '../ui/TextInput';
import Image from 'next/image';
// @ts-ignore
import McAfee from '../../../public/mcafee-badge.png';
import { Control, Controller } from 'react-hook-form';

export default function CreditCardFields(props: {
  className?: string;
  errors: any;
  control: Control<any, any>;
  watch: (_field: string) => any;
}) {
  const amexMask = '0000 000000 00000';
  const visaMCMask = '0000 0000 0000 0000';
  const isAmex = props.watch('creditCardNumber')?.match(/^3[47]/);
  // amex cards start with 34 or 37, have a 15 digit number
  const ccMask = isAmex ? amexMask : visaMCMask;
  const cvcMask = isAmex ? '0000' : '000';

  return (
    <div className={clsx('flex flex-col', props.className)}>
      <div className="flex w-full flex-row">
        <Controller
          control={props.control}
          name="creditCardNumber"
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              label="Credit Card Number"
              className="flex-1 mb-4"
              errorMessage={props.errors.creditCardNumber?.message}
              id="cc-input"
              autoComplete="cc-number"
              mask={ccMask}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            />
          )}
        />
      </div>
      <div className="flex flex-row items-center flex-1">
        <Controller
          control={props.control}
          name="ccExpires"
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              className="flex-1"
              label="Expiration MM/YY"
              errorMessage={props.errors.ccExpires?.message}
              mask={'00/00'}
              id="ccexp-input"
              autoComplete="cc-exp"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            />
          )}
        />
        <Controller
          control={props.control}
          name="cvc"
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              label="CVC"
              className="ml-4 flex-1"
              errorMessage={props.errors.cvc?.message}
              mask={cvcMask}
              id="cvc-input"
              autoComplete="cc-csc"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            />
          )}
        />
        <div className="w-[100px] h-9 ml-6 sm:hidden lg:block">
          <Image alt="McAfee Secure" src={McAfee} />
        </div>
      </div>
    </div>
  );
}
