import { useRouter } from 'next/router';
import React from 'react';
import Tile from './Tile';
import Button from './Button';

const NoUpcomingTile = ({
  hasVoucher,
  hasAnyJobs,
}: {
  hasVoucher?: boolean;
  hasAnyJobs: boolean;
}) => {
  const router = useRouter();

  return (
    <Tile className="h-[250px] justify-center items-center flex flex-col">
      <h2 className="text-xl text-center font-bold z-[1]">
        You have no upcoming appointments
      </h2>
      <Button
        className="z-10 mt-6 self-center"
        onClick={() =>
          router.push(hasVoucher && !hasAnyJobs ? '/redeem' : '/book')
        }
        aria-label="Book an appointment now"
      >
        Book one now
      </Button>
    </Tile>
  );
};

export default React.memo(NoUpcomingTile);
