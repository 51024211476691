import colors from '@/utils/colors';
import clsx from 'clsx';
import Image from 'next/image';
import { useMemo, useState } from 'react';

const COLORS = [colors.brand.yellow, colors.brand.green, colors.brand.teal];

function numberFromText(text: string) {
  const charCodes = text
    .split('') // => ["A", "A"]
    .map((char) => char.charCodeAt(0)) // => [65, 65]
    .join(''); // => "6565"
  return parseInt(charCodes, 10);
}
type UsernameAvatarProps = {
  color?: string;
  photo?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  border?: boolean;
  size?: number;
  className?: string;
  textColor?: string;
};

export default function UsernameAvatar({
  color,
  firstName,
  lastName,
  border,
  photo,
  size = 72,
  textColor,
  className,
}: UsernameAvatarProps) {
  const [loaded, setLoaded] = useState(false);
  const abbr = useMemo(
    () =>
      `${firstName?.substring(0, 1) ?? ''}${lastName?.substring(0, 1) ?? ''}`,
    [firstName, lastName],
  );
  const backgroundColor = useMemo(
    () => (color ? color : COLORS[numberFromText(abbr) % COLORS.length]),
    [abbr, color],
  );

  return (
    <div
      className={clsx(
        `relative flex justify-center self-center rounded-lg overflow-hidden flex-shrink-0 ${
          border ? 'border-2 border-white' : ''
        }`,
        className,
      )}
      style={{
        backgroundColor,
        width: size,
        height: size,
      }}
    >
      {!loaded || !photo ? (
        <p
          style={{ color: textColor ?? colors.brand.gray.darker }}
          className={`uppercase self-center ${
            size > 48 ? 'text-[36px]' : 'text-[18px]'
          }`}
        >
          {abbr}
        </p>
      ) : null}
      {photo ? (
        <Image
          src={photo}
          unoptimized
          alt={firstName + ' ' + lastName}
          fill
          className="w-full h-full object-cover"
          onLoad={() => setLoaded(true)}
        />
      ) : null}
    </div>
  );
}
