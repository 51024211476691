import { gql } from 'src/__generated__';

export const CUSTOMER_DATA = gql(/* GraphQL */ `
  query CustomerDashboard($keysWithOptions: [ExperimentLookupConfig]) {
    me {
      ... on Customer {
        id
        canBookWithoutCreditcard
        numInvoicedJobs
        hasCompletedCustomerSurvey
        cashBackProgram {
          cashOutDiscountRate
        }
        savedPaymentMethod {
          id
          walletType
          last4
          funding
        }
        vouchers {
          id
          merchant
          hours
        }
        customerReferralTerms {
          benefitsReceivedAt
          hostBenefitCredit
          guestBenefitCredit
        }
        legacyAlerts {
          ... on FirstJobAlert {
            start
          }
          ... on SystemCancelJobAlert {
            starts
          }
          ... on FailedChargesAlert {
            message
          }
          ... on CreditsAvailableAlert {
            creditsAvailable
          }
        }
        experiments(keysWithOptions: $keysWithOptions) {
          key
          value
        }
        forevercleanMembership {
          id
          rawId
          status
          paidThrough
        }
      }
    }
  }
`);
