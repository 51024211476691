import { AppUserQuery } from '@/utils/narrow-utils';
import { CustomerDashboardQuery } from 'src/__generated__/graphql';
import { RewardsBanner } from '../../Banners/RewardsBanner';
// import { ReferalBanner } from '../../Banners/ReferalBanner';
import { MembershipCancelledBanner } from '../../Banners/MembershipCancelledBanner';
import { useMemo } from 'react';
import UpdateProfileBanner from '../../Banners/UpdateProfileBanner';

interface BannersProps {
  customerData?: AppUserQuery<CustomerDashboardQuery, 'Customer'> | null;
  customerDataLoading: boolean;
  experiments: Record<string, string | undefined | null>;
}

const Banners = ({
  customerData,
  customerDataLoading,
  experiments,
}: BannersProps) => {
  const showProfileBanner = useMemo(() => {
    if (customerDataLoading) {
      return false;
    }
    if (typeof localStorage === 'undefined') return false;
    return !customerData?.me?.hasCompletedCustomerSurvey;
  }, [customerDataLoading, customerData?.me?.hasCompletedCustomerSurvey]);

  return (
    <>
      {/* Hide - only used by ~130 guests per month */}
      {/* {!!customerData?.me?.customerReferralTerms?.hostBenefitCredit &&
        !!customerData?.me?.numInvoicedJobs && (
          <ReferalBanner
            loading={customerDataLoading}
            hostBenefitCredit={
              customerData.me.customerReferralTerms.hostBenefitCredit
            }
          />
        )} */}
      {!!customerData?.me?.numInvoicedJobs &&
        !!customerData.me.cashBackProgram && (
          <RewardsBanner
            loading={customerDataLoading}
            cashOutDiscountRate={
              customerData.me.cashBackProgram.cashOutDiscountRate
            }
          />
        )}
      {!!customerData?.me?.forevercleanMembership?.status &&
        experiments['new_fc_cancellation_flow_v4'] !== 'control' && (
          <MembershipCancelledBanner
            membershipStatus={customerData.me.forevercleanMembership.status}
            paidThrough={customerData.me.forevercleanMembership.paidThrough}
          />
        )}
      {showProfileBanner && <UpdateProfileBanner />}
    </>
  );
};

export default Banners;
